<template>
    <v-sheet tile height="100%" elevation="2" color="white" class="tile" @click="$emit('click')" :class="{selected: selected, clickable: value.link || value.shop}">
        <div class="content" :class="{vertical: value.w <= value.h}">
            <v-flex class="partimg" v-if="value.img">
                <v-img :src="value.img" cover width="100%" height="100%" contain/>
            </v-flex>
            <v-layout d-flex column justify-center align-center v-if="value.txt" class="part pa-3">
                <div class="text-center headline">{{value.txt}}</div>
                <div class="text-center text--secondary word-break" v-html="value.subtxt" v-if="value.subtxt"></div>
            </v-layout>
        </div>
    </v-sheet>
</template>

<script>
    export default {
        name: "OrylTile",
        props: {
            value: Object,
            selected: Boolean
        }
    }
</script>

<style scoped>
    .word-break {
        word-break: break-word;
    }

    .tile {
        cursor: pointer;
        display: flex;
        user-select: none;
    }

    .content {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .part {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: calc(1/2 * 100%);
        word-break: break-all;
    }

    .partimg {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: calc(1/2 * 100%);
    }

    .vertical {
        flex-direction: column;
    }

    .vertical .part {
        min-height: calc(1/3 * 100%);
        min-width: 100%;
    }

    .vertical .partimg {
        min-height: calc(2/3 * 100%);
        min-width: 100%;
    }

    .clickable {
        cursor: pointer;
    }

    .selected {
        filter: brightness(50%);
    }
</style>
